<template>
  <div class="documents-component">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />
    <DataTable
      class="p-datatable-sm p-datatable-striped p-datatable-gridlines"
      ref="documentsTable"
      :value="filteredDocuments"
      :paginator="true"
      :loading="loading"
      dataKey="id"
      :rowHover="true"
      :rows="100"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[25, 50, 100, 1000]"
      currentPageReportTemplate="Zeige {first} bis {last} von {totalRecords} Einträgen"
      :expandedRows.sync="expandedRows"
      @row-expand="onRowExpand"
      @row-collapse="onRowCollapse"
      stateStorage="local"
      stateKey="documents-table-filters"
      :filters.sync="filters"
      filterDisplay="row"
      responsiveLayout="scroll"
      @state-restore="onStateRestore($event)"
    >
      <template #header>
        <div class="table-header">
          <div class="table-header-left">
            <h1 class="page-title">{{ $t('editor') }}</h1>
          </div>
          <div class="table-header-right">
            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText type="text" v-model="filters['global']" :placeholder="$t('search')" />
            </span>

            <div>
              <Dropdown
                v-model="customFilters.powerCompany"
                :options="filteredPowerCompanies"
                optionLabel="company"
                :placeholder="$t('powerCompany')"
                :showClear="true"
                @input="onSelectPowerCompany"
                style="width: 12.5em"
              />
            </div>

            <div class="button-right">
              <Button class="btn btn-narrow btn-inverse ml-2 mb-2" @click="expandAll">
                Alle öffnen
              </Button>
              <button class="btn btn-narrow btn-inverse ml-2 mb-2" @click="collapseAll">
                Alle schliessen
              </button>
              <button class="btn btn-inverse mb-2 ml-2" @click.prevent="onRemoveFilters()">
                <i class="fa fa-remove mr-3"></i>
                {{ $t('remove_filters') }}
              </button>
              <router-link class="btn btn-success mb-2 right" :to="{ name: 'DocumentCreatePage' }">
                <i class="fa fa-plus-circle mr-2"></i>
                {{ $t('documentCreate') }}
              </router-link>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        {{ $t('no_data_found') }}
      </template>
      <template #loading>
        {{ $t('loading') }}
      </template>
      <Column :expander="true" :headerStyle="'width: 30px'" />

      <Column
        field="number"
        header="#"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ width: '100px' }"
        :bodyStyle="{ 'text-align': 'center' }"
      >
        <template #body="slotProps">
          <router-link
            :to="{
              name: 'DocumentEditPage',
              params: { documentNumber: slotProps.data.number },
            }"
            >{{ slotProps.data.number.toString().padStart(4, '0') }}</router-link
          >
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column field="name" :header="$t('name')" sortable :showFilterMenu="false">
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <Column
        field="type"
        :header="$t('type')"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ width: '120px' }"
        :bodyStyle="'text-align: center;width: 120px'"
      >
        <template #body="slotProps">
          {{ $t(slotProps.data.type) }}
        </template>
        <template #filter="{filterModel, filterCallback}">
          <Dropdown
            v-model="filterModel.value"
            :options="getEnumValues('DocumentType')"
            class="p-column-filter"
            :showClear="true"
            @input="filterCallback()"
          >
            <template #option="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>

      <Column
        field="mailConstraintState"
        :header="$t('mailConstraintState')"
        sortable
        :showFilterMenu="false"
      >
        <template #body="slotProps">
          {{ $t(slotProps.data.mailConstraintState) }}
        </template>
        <template #filter="{filterModel, filterCallback}">
          <Dropdown
            v-model="filterModel.value"
            :options="getEnumValues('MailConstraintState')"
            class="p-column-filter"
            :showClear="true"
            @input="filterCallback()"
          >
            <template #option="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>

      <Column
        field="approvedState"
        :header="$t('approvedState')"
        sortable
        :showFilterMenu="false"
        :headerStyle="{ width: '120px' }"
        :bodyStyle="{ 'text-align': 'center', width: '120px' }"
      >
        <template #body="slotProps">
          <b-badge :variant="documentApprovedStateColor(slotProps.data.approvedState)">
            {{ $t(slotProps.data.approvedState) }}
          </b-badge>
        </template>
        <template #filter="{filterModel, filterCallback}">
          <Dropdown
            v-model="filterModel.value"
            :options="getEnumValues('DocumentApprovedState')"
            class="p-column-filter"
            :showClear="true"
            @input="filterCallback()"
          >
            <template #option="slotProps">
              <b-badge :variant="getYesNoStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>

      <Column
        field="description"
        :header="$t('description')"
        sortable
        filterMatchMode="contains"
        :showFilterMenu="false"
      >
        <template #body="slotProps">
          <span v-html="slotProps.data.description"></span>
        </template>
        <template #filter="{filterModel,filterCallback}">
          <InputText type="text" v-model="filterModel.value" @input="filterCallback()" />
        </template>
      </Column>

      <template #expansion="slotProps">
        <div class="expansion">
          <ul v-if="slotProps.data.type === 'NET_REGISTRATION'">
            <li
              v-for="(powerCompany, idx) in slotProps.data.netRegistrationPowerCompanies"
              :key="idx"
            >
              <router-link
                :to="{
                  name: 'PowerCompanyEditPage',
                  params: { powerCompanyNumber: powerCompany.number },
                }"
                target="_blank"
              >
                {{ powerCompany.company }}</router-link
              >
            </li>
          </ul>
          <ul v-if="slotProps.data.type === 'OPERATION_REGISTRATION'">
            <li
              v-for="(powerCompany, idx) in slotProps.data.operationRegistrationPowerCompanies"
              :key="idx"
            >
              <router-link
                :to="{
                  name: 'PowerCompanyEditPage',
                  params: { powerCompanyNumber: powerCompany.number },
                }"
                target="_blank"
              >
                {{ powerCompany.company }}</router-link
              >
            </li>
          </ul>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Breadcrumbs from '@/components/Breadcrumbs';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import { getYesNoStateColor, getDocumentApprovedStateColor } from '@/helpers/colors';
import { yesNo } from '@/helpers/enums';
import isEqual from 'lodash/isEqual';
import { FilterMatchMode } from 'primevue/api';

export default {
  name: 'DocumentsPage',
  components: {
    Breadcrumbs,
    DataTable,
    InputText,
    Column,
    Dropdown,
  },
  props: ['msg'],
  data() {
    return {
      loading: true,
      customFilterName: 'documents-custom-table-filters',
      defaultCustomFilters: {
        powerCompany: null,
      },
      customFilters: {
        powerCompany: null,
      },
      filters: {
        number: { value: null, matchMode: FilterMatchMode.EQUALS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        mailConstraintState: {
          value: null,
          label: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        type: {
          value: null,
          label: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        approvedState: {
          value: null,
          label: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        rowsPerPage: 100,
      },
      defaultFilters: {
        number: { value: null, matchMode: FilterMatchMode.EQUALS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
        mailConstraintState: {
          value: null,
          label: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        type: {
          value: null,
          label: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        approvedState: {
          value: null,
          label: null,
          matchMode: FilterMatchMode.EQUALS,
        },
        rowsPerPage: 100,
      },
      expandedRows: [],
      filteredPowerCompanies: [],
      filteredDocuments: null,
    };
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUser']),
    ...mapGetters([
      'isLoading',
      'getDocuments',
      'getDocument',
      'getPowerCompanies',
      'getEnumValues',
    ]),
    breadcrumbs() {
      return [{ name: 'Home', route: { name: 'home' } }, { name: this.$t('editor') }];
    },
    optionsYesNo() {
      return yesNo();
    },
  },
  methods: {
    ...mapActions([
      'fetchDocuments',
      'fetchPowerCompanies',
      'updateUserSettings',
      'fetchEnumValues',
    ]),
    getYesNoStateColor,
    yesNo,
    yesNoColor(state) {
      return getYesNoStateColor(state);
    },
    documentApprovedStateColor(state) {
      return getDocumentApprovedStateColor(state);
    },
    onRowExpand(event) {},
    onRowCollapse(event) {},
    expandAll() {
      this.expandedRows = this.getDocuments.filter((d) => d.number);
    },
    collapseAll() {
      this.expandedRows = null;
    },

    onFilter(filter) {
      console.log('onFilter()', filter);
    },

    /**
     * Remove all filters
     */
    onRemoveFilters() {
      this.sortOrder = null;
      this.sortField = null;
      this.filters = this.defaultFilters;
      this.customFilters = this.defaultCustomFilters;
      this.filterDocuments(null);
    },

    /**
     * Triggered when searching for a power company in the dropdown in table header
     */
    onPowerCompanySearch(query) {
      this.filteredPowerCompanies = [];
      this.getPowerCompanies.map((powerCompany) => {
        if (powerCompany.company && powerCompany.company.toLowerCase().indexOf(query) >= 0) {
          this.filteredPowerCompanies.push(powerCompany);
        }
      });
    },

    /**
     * Triggered when selecting a power company from the dropdown in table header
     */
    onSelectPowerCompany(value) {
      console.log('onSelectPowerCompany()');
      this.customFilters.powerCompany = value;
      this.saveCustomFiltersToLocalStorage();
      this.filterDocuments(value);
    },

    /**
     * Triggered by datatable when filters are loaded from local storage
     */
    async onStateRestore(event) {
      this.getCustomFiltersFromLocalStorage();
      this.filterDocuments(this.customFilters.powerCompany);
    },

    /**
     * Filter Documents by selected power company
     */
    filterDocuments(value) {
      this.filteredDocuments = [];
      if (!value) {
        this.filteredDocuments = this.getDocuments;
        console.log('filterDocuments()', this.filteredDocuments);
        return;
      }
      this.getDocuments.map((document) => {
        document.netRegistrationPowerCompanies.map((powerCompany) => {
          if (value.company === powerCompany.company) {
            this.filteredDocuments.push(document);
          }
        });
        document.operationRegistrationPowerCompanies.map((powerCompany) => {
          if (value.company === powerCompany.company) {
            this.filteredDocuments.push(document);
          }
        });
      });
    },

    saveCustomFiltersToLocalStorage() {
      localStorage.setItem(this.customFilterName, JSON.stringify(this.customFilters));
    },
    getCustomFiltersFromLocalStorage() {
      const customFiltersFromStorage = JSON.parse(localStorage.getItem(this.customFilterName));

      this.customFilters = customFiltersFromStorage
        ? customFiltersFromStorage
        : this.defaultCustomFilters;
    },
  },

  async mounted() {
    await this.fetchDocuments();
    await this.fetchPowerCompanies();
    await this.fetchEnumValues('MailConstraintState');
    await this.fetchEnumValues('DocumentType');
    await this.fetchEnumValues('DocumentApprovedState');
    this.filteredPowerCompanies = this.getPowerCompanies;
    this.loading = false;
    this.filterDocuments(this.customFilters.powerCompany);
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}
.table-header-left {
  display: flex;
  justify-content: flex-start;
}
.table-header-right {
  display: flex;
  justify-content: flex-end;
  column-gap: 1em;
}
.expansion {
  text-align: left;
  margin-left: 3.5em;
}
/** Fix Dropdown overlay */
::v-deep .p-datatable-scrollable-header {
  overflow: visible;
}
.btn-narrow {
  padding: 0.375rem 1rem;
  max-height: 33.5px;
}
</style>
